@use 'assets/scss/mixins.scss' as mixins;

.greeting {
    .jumbotron {
        background: #fff;
        margin: 0;
        padding: 2.5rem 0;
        
        h1 {
            background: #fff;
            @include mixins.theme('color', 'ui-primary-color');
        }

        section {
            display: flex;
            align-items: flex-start;

            div:first-child {
                @include mixins.jumbo-buttons;
                margin-right: 10px;
            }

            div:last-child {
                @include mixins.jumbo-buttons;
                margin-left: 10px;
            }
        }
    }
}

.dashboard {
    table {
        margin-bottom: .2rem;
    }
}

.update-center {
    margin-top: 10px;
    .padding {padding-top: 15px;}

    section:first-of-type {
        border-radius: 0 10px 0 0;
        .title {border-radius: 10px 10px 0 0;}
    }

    section:last-of-type {
        border-radius: 0 0 10px 10px;
        .content{border-radius: 0 0 10px 10px;}
    }

    section {
        font-size: .70rem;
        @include mixins.theme('background', 'ui-update-center-background');

        .title {
            @include mixins.theme('background', 'ui-primary-color');
            color: #fff;
            padding: 5px 28px;
            box-sizing: border-box;
        }

        .content{
            border: 2px solid;
            @include mixins.theme("border-color", 'ui-primary-color');
            ul { margin: 5px 0 !important; line-height: 10px;}
        }
    }
}

.jobs {
    & > a {
        @include mixins.more-link;
        font-size: .75em;
    }
}


.jobs-hidden {
    display: none;
}

.dropdown-container {
    .dropdown .dropdown-menu {
        max-height: 176px;
    }
}


@media (max-width: 695px) {
    .greeting {
        .jumbotron {
            padding: 2rem 0 !important;
            section {
                display: initial !important;
                div {
                    margin: 0 0 10px !important;
                }
            }
        }
    }

    .update-center {
        padding: 0 !important;
        width: 420px !important;
        h2 {border-top-left-radius: 0 !important; font-size: .95em !important; padding: 10px 5px !important; margin-top: 10px !important; }

        section {
            margin-right: 15px;
            margin-left: 15px;
        }
    }

    .bottom-padding-50 {
        box-sizing: border-box;
        padding-bottom: 50px;
    }

    .bottom-padding-100 {
        box-sizing: border-box;
        padding-bottom: 100px;
    }

    .mobile-menu {
        margin: 0;
        padding: 0 3px;
        list-style: none;
        background: #fff;
        position: fixed;
        left: 0;
        right:0;
        bottom: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            flex: 1 1 calc(50% - 6px);
            box-sizing: border-box;
            margin: 6px 3px 0;

            button, button:hover, button:active, button:focus {
                @include mixins.theme('background', 'ui-primary-color');

                & {
                    border: 0;
                    box-shadow: none;
                    color: #fff;
                    font-weight: bold;
                    border-radius: 8px 8px 0 0;
                    width: 100%;
                    height: 45px;
                }
            }
        }
    }

    .mobile-show{display: block;}
    .mobile-hide{display: none;}

    .jobs .mobile-show {
        & > a{
            @include mixins.more-link;
            font-size: .95em;
        }
    }

    .jobs {
        padding: 0 !important;
        
        h2 {border-top-left-radius: 0 !important; font-size: .95em !important; padding: 10px 5px !important; }

        .table {
            thead {
                tr {
                    th {
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .greeting {
        .jumbotron {
            padding: 2rem 0 !important;
            section {
                display: initial !important;
                div {
                    margin: 0 0 10px !important;
                }
            }
            h1 {
                margin-left: 0 !important;
            }
        }
    }
}