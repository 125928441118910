@use 'assets/scss/mixins.scss' as mixins;

.cbc-form {
    box-sizing: border-box;
    padding: 1em;

    #deliveryInstructions {
        height: 70px;
    }

    .job-button {
        width: 100%;
    }
}

.job-form {
    .form-group label {
        font-weight: 700 !important;
    }

    .cbc-radio label {
        font-weight: 500 !important;
    }
}

.form-note {
    @include mixins.theme('border-color', 'ui-primary-color');
    border: 1px solid;
    box-sizing: border-box;
    font-size: 0.75em;
    background: #f2f1f0;
    padding: 10px;
    border-radius: 8px;
    color: #6b6f70;

    ul {
        padding-left: 14px;
    }
}

.top-contents {
    margin: 15px 0;

    .btn-job-list {
        display: inline-block;

        span {
            font-size: 0.8em !important;
        }

        img {
            width: 23px;
        }
    }
}

.jobStatusDropdown {
    font-weight: 500;
    margin-right: 10px;

    button[disabled] {
        @include mixins.theme('background', 'ui-primary-color');
        @include mixins.theme('border-color', 'ui-primary-color');
        opacity: 1;
        font-size: 0.85em;
        border-radius: 8px;
    }

    .dropdown-toggle {
        @include mixins.theme('background', 'ui-secondary-color');
        @include mixins.theme('border-color', 'ui-secondary-color');
        font-size: 0.85em;
        border-radius: 8px;
        border-bottom-right-radius: 0;

        label {
            margin: 0;
        }
    }

    .dropdown-menu {
        border: 0;
        border-radius: 0 0 8px 8px;
        padding: 0;

        .dropdown-item:nth-child(even) {
            @include mixins.theme('background', 'ui-stripe-second-color');
        }

        .dropdown-item:last-child {
            border-radius: 0 0 8px 8px;
        }

        .dropdown-item {
            font-weight: 500;
            font-size: 0.85em;
            color: #fff;
            @include mixins.theme('background', 'ui-stripe-first-color');
            padding: 8px 17px;
        }
    }
}

.fileContainer {
    margin-bottom: 15px;
    box-sizing: border-box;

    .fileSelector {
        border: 2px dashed #c5c9ca;
        border-radius: 8px;
        background: #dadedf;
        box-sizing: border-box;
        height: 150px;
        position: relative;

        input {
            width: 100%;
            height: inherit;
            opacity: 0;
        }

        .info {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            vertical-align: middle;
            text-align: center;
            padding-top: 20px;
            font-weight: 500;
            color: #8d8f90;
        }
    }

    .list-group {
        padding: 7px 20px;

        .fileListItem:last-child {
            border: 0;
        }

        .fileListItem {
            display: flex;
            border: 0;
            padding: 7px 0;
            border-bottom: 1px solid #e0e0e0;

            .type {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                border: 2px solid;
                @include mixins.theme('border-color', 'ui-primary-color');
                text-align: center;
                padding-top: 10px;
                box-sizing: border-box;
                text-transform: uppercase;
                font-weight: 700;
                @include mixins.theme('color', 'ui-primary-color');
                overflow: hidden;
                text-overflow: clip;
            }

            .nameSize {
                flex: 1;
                box-sizing: border-box;
                padding: 0 15px;

                div:first-child {
                    font-weight: 500;
                    font-size: 0.85em;
                }

                div:last-child {
                    font-size: 0.8em;
                    font-weight: 500;
                    color: #b5b5b5;
                }
            }

            button {
                padding: 0;
                margin: 0;
                width: 30px;
                height: 30px;
                border-radius: 30px;
                box-sizing: border-box;

                img {
                    width: 28px;
                    border: 2px solid gray;
                    border-radius: 28px;
                }
            }
        }
    }
}

.jobFormErrors {
    .alert {
        margin-top: 0;
    }
}

.job-details {
    button {
        width: 100%;
    }
}

.address_form_fields {
    .noBorderText {
        border: 0;
        background: transparent !important;
    }

    .noBorderText:focus {
        box-shadow: none;
    }
}

.dispatch-method {
    text-align: center;

    .dispatch-label {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        overflow: hidden;
        width: calc(100% - 4px);
        box-shadow: 7px 7px 0px -3px #dedede;

        border: 1px solid;
        @include mixins.theme('border-color', 'ui-secondary-color');
        border-radius: 4px;
        transition: 150ms width, 150ms box-shadow;
        margin-bottom: 0;

        img {
            width: 65%;
            margin: 0 auto;
        }

        span {
            @include mixins.theme('background-color', 'ui-secondary-color');
            color: white;
            font-weight: 600;
            font-size: 0.7em;
            padding: 5px;
            box-sizing: border-box;
        }
    }

    .cbc-radio .form-check {
        padding: 0;

        input {
            display: none;
        }

        label {
            visibility: hidden;
        }

        [type='radio']:checked + label::before {
            display: none;
        }

        label::after {
            display: none;
        }
    }
}

.dispatch-method.disabled,
.dispatch-method.active,
.dispatch-method:hover {
    .dispatch-label {
        width: 100%;
        @include mixins.theme('border-color', 'ui-primary-color');
        box-shadow: none;
        margin-bottom: 4px;

        span {
            @include mixins.theme('background-color', 'ui-primary-color');
        }
        margin-bottom: 5px;

        &.disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }

    .cbc-radio .form-check label {
        visibility: visible;
        width: 100%;
        height: 20px;
        text-align: center;
        font-size: 0.85em;
        font-style: italic;
        margin-left: 0;
        @include mixins.theme('color', 'ui-primary-color');
    }

    .cbc-radio.disabled {
        opacity: 0.3;
    }
}

@media (max-width: 695px) {
    .cbc-form {
        padding: 0 0 64px !important;

        &.reduced-pb {
            padding-bottom: 1.4em !important;
        }

        .mobile-buttons {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            padding: 10px 15px;
            background: white;
            box-shadow: 0 0 8px -1px grey;
        }
    }

    .jobListPage {
        padding: 0 !important;

        .top-contents {
            box-sizing: border-box;
            padding: 0 10px;
            display: flex;

            .btn-job-list {
                margin-left: 10px;
                flex: 1;
            }

            .jobStatusDropdown {
                margin: 0;

                .dropdown-toggle label {
                    display: none;
                }
            }
        }
    }

    h2 {
        border-top-left-radius: 0 !important;
    }
}
