@use 'assets/scss/mixins.scss' as mixins;

.job-dashboard {
    h1 {
        margin-bottom: 5px;
    }

    table {
        border-collapse: initial;

        thead {
            background: white;
        }

        thead tr th {
            font-size: 1.1em;
            padding: 0 1px;

            div {
                @include mixins.theme('background', 'ui-secondary-color');
                padding: 5px 0;
            }
        }
    }

    table thead tr th:last-child {
        border-radius: 0 0 15px 0;

        div {
            border-radius: 0 0 15px 0;
        }
    }

    table thead tr th:first-child {
        border-radius: 15px 0 0 15px;

        div {
            border-radius: 15px 0 0 15px;
        }
    }

    table tbody tr td {
        font-size: 1.1em;

        .total {
            font-weight: bold;
            @include mixins.theme('color', 'ui-secondary-color');
            font-size: 1.2em;
        }
    }

    table tbody tr td:last-child {
        margin-right: 15px;
    }

    table tbody tr.even {
        td:first-child {
            border-radius: 10px 0 0 10px;
        }
    }

    table tbody tr td:last-child {
        border-radius: 0 10px 10px 0;
    }
}

@media (max-width: 695px) {
    .job-dashboard .cbc-table {
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);

        thead tr th:first-child {
            border-radius: 0;
        }
        thead tr th div {
            margin-bottom: 0;
        }
        tbody tr.even td:first-child {
            border-radius: 0;
        }
        tbody tr.even td:last-child {
            border-radius: 0;
        }
    }
}
